// Override default variables before the import
// $body-bg: #000;


$btn-border-radius: 5px;
$btn-border-radius-sm: 5px;
$btn-border-radius-lg: 5px;
$font-family-sans-serif: "Poppins" !default;
$btn-font-weight: 600 !default;
$btn-font-size-lg: 14px !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';


.more {
    display: none;
}

.card img.w-100 {
    aspect-ratio: 2 / 1;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.card img.topHeader {
    aspect-ratio: 3/1;
}

.topRadius{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
// .card-body {
//     border-bottom-right-radius: 10px;
//     border-bottom-left-radius: 10px;

// }

.showMoreLess {
    font-weight: 600;
}

.logout {
    flex: 0;
    margin-left: auto;
    padding-right: 5px;
}

.center-logo {
    flex: 1;
    display: flex;
    justify-content: center;
}

.container.membership {
    padding-top: 20px;
}

.infoIcon {
    padding-left: 5px;
}

.nav-pills {
    padding-left: 15px;

    .nav-link {
        font-size: 12px;
        color: #111;

        &.active {
            font-weight: 600;
            color: #111;
            border-bottom: 3px #1d1d1d solid;
            border-radius: 0;
        }
    }

    .nav-item {
        button {
            background: none !important;
            color: #7b7b7b;
            font-weight: 600 !important;
        }
    }
}

@media (max-width: 795.98px) {
    // .container.membership.eventPage .card.mw-container {
    //     margin: 0 -25px !important;
    // }

    // .container.membership.eventPage {
    //     padding-top: 0;
    // }

    // .container.membership.eventPage .card img.w-100 {
    //     border-radius: 0;
    // }
}

.dateTitle {
    margin-top: 10px;
    display: block;
}

.event-ticket-date:not(:first-child) {
    border-top: 1px #eee solid;
    display: block;
}

.container.membership.allEvents .event-ticket-date:first-child {
    margin-top: 0;
    margin-bottom: 0;
}

.occurrence,
.occurrence:hover {
    color: #111;
}

.sold {
    opacity: 0.5;
}

.viewAll {
    font-size: 12px;
    color: #111;

    span {
        margin-left: 5px;
    }
}

.event-ticket-date {
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        cursor: pointer;
    }

    .month {
        font-size: 14px;
        text-transform: uppercase;
    }

    .day {
        font-size: 24px;
        font-weight: 600;
    }

    .month-day {
        margin-right: 15px;
        width: 50px;
        text-align: center;
    }
}

.wrap-date-location {
    width: 60%;

    .ticketSelect & {
        width: auto;
        margin-top: 5px;
    }

    .dateTitle {
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        margin-top: 0;
    }

    .location {
        font-weight: bold;
        display: flex;
        align-items: center;
        font-size: 13px;
        padding: 2px 0;
    }

    .orgName {
        font-size: 12px;
        display: block;
    }
}


.round-button {
    border-radius: 50%;
    width: 40px;
    border: none;
    height: 40px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    background: #50A939;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        background: #E5E5E5;
    }
}

.active {
    #minus-button {
        background: #999999;
    }
}

#selection-value {
    font-size: 15px;
    font-weight: 600;
    width: 40px;
    text-align: center;
    color: #2C2C2C;
}


.event-ticket {
    box-sizing: border-box;
    margin: 0 auto;

    // .name {
    //     margin-top: 5px;
    // }

    label.d-block & {
        border-radius: 0;
    }
}

.event-ticket.passed {
    background: #f9f8f8;
    opacity: 0.5;
}

.upcoming {
    font-size: 9px;
    font-weight: normal;
}

.tag-passed {
    background: #eee;
    border-radius: 3px 0 0 3px;
    color: #999;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    float: right;

    &::before {
        background: #fff;
        border-radius: 10px;
        box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
        content: '';
        height: 6px;
        left: 10px;
        position: absolute;
        width: 6px;
        top: 10px;
    }

    &::after {
        background: #fff;
        border-bottom: 13px solid transparent;
        border-left: 10px solid #eee;
        border-top: 13px solid transparent;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
    }
}

dt.event-price {
    font-size: 12px;
    line-height: 20px;
    color: #1d1d1d;
}

.orgHomeSec{
    font-size: 13px;
    font-weight: bold;
}
.orgHomeSecSub{
    display: block;
    font-size: 12px;
}

.expireIn {
    background: #1e1d1d;
    color: #fff;
    padding: 6px;
    margin: 0px;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 4px;
    margin-bottom: -1px;
    margin-right: 4px;

    .timer {
        color: #f59a05;
        font-size: 12px;
        font-weight: 600;
        margin: 0 5px;
    }
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid black;
    z-index: 9999;
    width: 400px;
    height: 200px;
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    max-width: 100%;
}

.checkImg {
    margin-bottom: 15px;
}

footer{
    font-size: 14px;
}

.border-b{
    border-bottom: 1px solid #eee;
}
.wrap-ticket-name{
    width: auto;
    margin-top: 5px;
}

.ticketForm{
    margin-top: 20px;
}
.ticketName{
    font-size: 10px;
    font-weight: normal;
}

.formCount{
    font-size: 12px;
}
.formCountNr{
    font-size: 12px;
    letter-spacing: 3px;
}

.card{
    border:none;
}

.form-item{
    font-size: 12px;

    .form-check{
        display: flex;
        align-items: center;
    }
    .form-check-input{
        margin-top: 0;
    }

    .form-item-title{
        font-weight: 200;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 0;
    }
}

input::placeholder {
    font-size: 14px; /* Change this value to the desired font size */
}